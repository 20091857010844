import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Hackathon3 from '../../images/hackathon-3.jpg'
import Hackathon4 from '../../images/hackathon-4.jpg'
import Hackathon5 from '../../images/hackathon-5.jpg'
import Hackathon6 from '../../images/hackathon-6.jpg'
import Hackathon7 from '../../images/hackathon-7.jpg'
import Hackathon8 from '../../images/hackathon-8.jpg'
import Hackathon9 from '../../images/hackathon-9.jpg'
import Hackathon10 from '../../images/hackathon-10.jpg'
import Insight from '../../images/hackathon-insight.png'
import Ideation from '../../images/hackathon-ideation.png'
import Inspiration from '../../images/get-inspired.png'
import Meetup from '../../images/hackathon-meetup.jpg'
import FacebookGroup from '../../images/hackathon-fb.png'
import Budget from '../../images/budget.png'
import Game from '../../images/hackathon-game.jpg'
import Partner from '../../images/hackathon-partner.jpg'
import Sponsor1 from '../../images/hackathon-sponsor-incentive.png'
import Tiers from '../../images/hackathon-sponsor-tiers.png'
import Renny from '../../images/renny.jpg'
import Marketing1 from '../../images/1.png'
import Marketing2 from '../../images/2.png'
import Marketing3 from '../../images/3.png'
import Marketing4 from '../../images/4.png'
import Marketing5 from '../../images/5.png'
import Marketing6 from '../../images/6.png'
import Marketing7 from '../../images/7.png'
import Marketing9 from '../../images/9.png'
import Marketing10 from '../../images/10.png'
import Logo1 from '../../images/hackathon-logo-inspiration.jpeg'
import Logo2 from '../../images/hackathon-logo-drawing.jpeg'
import Logo3 from '../../images/hackathon-logo-final.png'
import AwesomeSlider from 'react-awesome-slider'
import 'react-awesome-slider/dist/styles.css'
import SliderStyle from '../../components/slider.scss'

export default function Hackathon(){
    const data = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "hackathon-1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      two: file(relativePath: { eq: "hackathon-2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
  <Layout>
    <SEO title="hackathon" />
    <div className="c12 title main">
        <h1>Hack For Olim Hackathon</h1>
    </div>
    <div className="c12 main">
        <div className="summary">
            <div className="summary-section">
                <div className="section summary-item">
                    <h5 className="subtitle">Overview</h5>
                    <p>
                        An event encouraging people from different backgrounds to collaborate, learn more about each other, and maybe even become friends.
                    </p>
                </div>
                <div className="section summary-item">
                    <h5 className="subtitle">Project Type</h5>
                    <p>Voluntary, outside of work</p>
                </div>
                <div className="section summary-item">
                    <h5 className="subtitle">Role</h5>
                    <p>Organizer</p>
                </div>
                <div className="section summary-item">
                    <h5 className="subtitle">Team</h5>
                    <p>Eden A.</p>
                    <p>Omri I.</p>
                </div>
                <div className="section summary-item">
                    <h5 className="subtitle">Timeline</h5>
                    <p>February 2019 - received grant</p>
                    <p>March 2019 - began planning with Omri</p>
                    <p>August 2019 - hackathon</p>
                </div>
                <div className="section summary-item">
                    <h5 className="subtitle">Results</h5>
                    <h4 className="subtitle">💵 $21,000 raised</h4>
                    <h4 className="subtitle">✋ 220 applicants</h4>
                    <h4 className="subtitle">🤖 125 hackers accepted & attended</h4>
                    <h4 className="subtitle">👑 7 company sponsors</h4>
                    <h4 className="subtitle">🕑 17 hours</h4>
                    <h4 className="subtitle">🌍 10+ countries represented</h4>
                    <h4 className="subtitle">🍔 4 catered meals</h4>
                    <h4 className="subtitle">🎤 1 local celebrity guest MC</h4>
                </div>
            </div>
            <div>
                <Img fluid={data.one.childImageSharp.fluid} className="summary-section" style={{ width: "100%" }}/>
                <br/>
                <Img fluid={data.two.childImageSharp.fluid} className="summary-section" style={{ width: "100%" }}/>
            </div>
        </div>
    </div>
    <div className="grid">
        <div className="c6 divider"></div>
        <div className="c6">
            <div className="section">
                <h5 className="subtitle">Background</h5>
                <h2 className="hackathon-title">Who are Olim?</h2>
                <p>There are many different populations living in Israel, one of which is "olim" or immigrants (Hebrew, plural). Another group is ex-pats who are living in Israel but are not citizens. For simplicity, I'll be referring to these groups as <b>non-native Israelis</b>.</p>
            </div>
            <div className="section">
                <h5 className="subtitle">Problem</h5>
                <h2 className="hackathon-title">Small country, wide gaps</h2>
                <p>
                As a non-native Israeli who immigrated to Israel in my 20s, I struggled to make deep connections with native Israelis. They already had an established network and community, accentuated by their mandatory military service, and it was difficult to penetrate or be invited in. When I got my first job as a software engineer, I wanted to feel part of the rich and vibrant Israeli tech ecosystem.
                </p>
                <br/>
                <p>
                I heard similar feedback from fellow non-native Israelis. This lack of connection and engagement with the Israeli tech ecosystem made it more difficult for these individuals to find jobs, as the culture in Israel is highly reliant on referrals and close connections, and they often felt alienated from their peers in the tech community.
                </p>
                <img src={Hackathon5} className="img" />
            </div>
            <div className="c6 divider"></div>
            <div className="section">
                <h5 className="subtitle">Interviews</h5>
                <h2 className="hackathon-title">Separate bubbles</h2>
                <p>I interviewed many native and non-native Israelis asking them how they became friends with or met people in their network who are non-native or native Israelis respectively.</p>
                <br/>
                <p>I discovered these common categories:</p>
                <br/>
                <div className="portfolio-content">
                    <div className="">
                        <img src={Insight} className="img"/>
                    </div>
                    <div className="">
                        <p>I synthesized all of the research findings and discovered that the common, underlying theme was that these individuals formed relationships, and thus expanded their network, through <b>shared experiences</b>.</p>
                    </div>
                </div>
            </div>

            <div className="section">
                <h5 className="subtitle">Insight</h5>
                <h2 className="hackathon-title">What's in it for me?</h2>
                <p>Based on my past experiences organizing events for Olim in Tech for both native and non-native Israelis, I learned that in order to attract native Israelis, there needs to be some <b>incentive</b>, something they gain, in order for them to join. The added value of expanding their network and meeting other non-native Israelis is not enough.</p>
                <br/>
                <p>The event must also be <b>interactive</b> to allow ample opportunity for individuals to gain a sense of shared experience.</p>
                <br/>
                <img src={Hackathon7} className="img" />
            </div>
            <div className="c6 divider"></div>
            <div className="section">
                <h5 className="subtitle">Challenge statement</h5>
                <h4>How might we offer an opportunity for shared experiences between native and non-native Israelis within the Israeli tech ecosystem?</h4>
            </div>
            <div className="c6 divider"></div>
            <div className="section">
                <h5 className="subtitle">Ideation</h5>
                <h2 className="hackathon-title">Hack it</h2>
                <p>I wanted to come up with an event that was familiar to native Israelis.</p>
                <br/>
                <div className="portfolio-content">
                    <div className="">
                    <img src={Ideation} className="img"/>
                    </div>
                    <div className="">
                        <p>Hackathons are a globally recognized event within the tech community. Native Israelis enjoy attending hackathons, however non-native Israelis often feel intimidated by these hackathons since they are mostly held in Hebrew.</p>
                        <br/>
                        <p>I saw this as an opportunity to make hackathons more accessible to the non-native Israeli community and create a shared experience for both native and non-native Israelis.</p>
                    </div>
                </div>
                <br/>
            </div>

            <div className="section">
                <h5 className="subtitle">Execution</h5>
                <h2 className="hackathon-title">Pair programming</h2>
                <div className="portfolio-content">
                    <div className="">
                    <img src={Partner} className="img"/>
                    </div>
                    <div className="">
                        <br/>
                        <p>I sought out a partner to help me organize this event. I was connected with Omri who is a native Israeli and has previous experience organizing hackathons.</p>
                    </div>
                </div>
            </div>

            <div className="section">
                <h2 className="hackathon-title">Theme</h2>
                <p>Instead of choosing a theme like AI or chatbots, we wanted to use this event as an opportunity for native Israelis to gain insight into the challenges olim, new immigrants, face in Israel.</p>
                <br/>
                <p>We chose for the theme to be "Hack for Olim" where everyone will work together towards addressing the challenges olim face in Israel.</p>
                <br/>
                <div className="portfolio-content">
                    <div className="">
                        <img src={Inspiration} className="img"/>
                    </div>
                    <div className="">
                        <br/>
                        <p>We encouraged all participants to ask olim about challenges they face, and also did some research on my own. I put together this resource for everyone to gain project inspiration.</p>
                    </div>
                </div>
            </div>

            <div className="section">
                <h2 className="hackathon-title">Diversifying teams</h2>
                <p>We encouraged participants to create diverse teams and facilitated this process by creating opportunities to form teams both online and in-person. We created a Facebook group and meetup event.</p>
                <br/>
                <div className="portfolio-content">
                    <div className="">
                        <img src={FacebookGroup} className="img"/>
                        <p>Examples of posts from the group of 230+ people, where individuals sought out or recruited others to join their team.</p>
                    </div>
                    <div className="">
                        <img src={Meetup} className="img"/>
                        <p>Native and non-native Israelis meeting one another and teaming up.</p>
                    </div>
                </div>
            </div>

            <div className="section">
                <h2 className="hackathon-title">Seeking Sponsors</h2>
                <p>We needed funding to support the hackathon. It's common for hackathons to partner with tech companies since it is a mutually beneficial opportunity.</p>
                <br/>
                <p>We interviewed companies asking them what motivates them to sponsor events and what value partnerships can provide them.</p>
                <br/>
                <p>We synthesized their feedback into two categories: <b>recruitment</b> and <b>giving back to the community.</b></p>
                <br/>
                <div className="portfolio-content">
                    <div className="">
                        <img src={Tiers} className="img"/>
                    </div>
                    <div className="">
                        <br/>
                        <p>We designed sponsorship packages to meet both the company and event needs.</p>
                    </div>
                </div>
                <br/>
                <img src={Hackathon4} className="img" />
                <p>Booking.com sponsor representatives and mentors handing out swag and helping participants</p>
            </div>

            <div className="section">
                <h2 className="hackathon-title">Budget with no bank</h2>
                <p>Since we are not an official non-profit organization, we don't have a bank account. So we needed to find a way to pay for items and services for the hackathon without any money passing through us.</p>
                <br/>
                <p>Challenges:</p>
                <ul>
                    <li>A few companies had policies dictating specific items they were allowed to pay for</li>
                    <li>Some companies could only pay for the item or service after 30 days</li>
                    <li>Certain services required immediate payment or downpayment</li>
                </ul>
                <p>Designing a "fluid" budget:</p>
                <ul>
                    <li>Prioritized must-have services: catering, T-shirts, photographers, event security and cleanup</li>
                    <li>Connected companies requiring 30 days for payment with services that accepted that form of payment</li>
                    <li>Omri negotiated with electronics company to allow us to pay 30 days later, and in exchange we'd purchase all prizes through them</li>
                    <li>Matched companies according to the services they could pay for</li>
                </ul>
                <img src={Budget} className="img"/>
                <p>Snapshot of how we organized our flexible budget.</p>
            </div>
{/* 
            <div className="section">
                <h2 className="hackathon-title">Inter-team mingling</h2>
                <p>We designed a game to encourage participants to form connections outside of their team, and even win a prize.</p>
                <br/>
                <div className="portfolio-content">
                    <div className="">
                        <img src={Game} className="img"/>
                    </div>
                    <div className="">
                        <br/>
                        <p>Each person was given a challenge and the name of a person to complete that challenge with. For example, getting that person to code on someone else's computer, or getting them to sing a line from their national anthem.</p>
                    </div>
                </div>
                <p>We anticipated that participants would be very busy with their projects and not have time to do the game, but it took minimal effort to organize, so it seemed worthwhile. Those who participated enjoyed it, and the game served as a great conversation starter between participants.</p>
            </div> */}

            <div className="section">
                <h2 className="hackathon-title">Finish with a bang</h2>
                <div className="portfolio-content">
                    <div className="">
                    <img src={Renny} className="img"/>
                    </div>
                    <div className="">
                        <br/>
                        <p>Omri and I wanted the final part of the hackathon to be exciting, engaging, and fun. After everyone worked 17 hours straight, we wanted to bring in someone with lots of energy to close the event. </p>
                    </div>
                </div>
                <p>I thought of Renny, a local comedian and new immigrant herself. She brought an immense amount of energy and kept everyone laughing and smiling.</p>
            </div>

            <div className="section">
                <h2 className="hackathon-title">Marketing</h2>
                <p>I designed the marketing materials and social media posts to generate buzz and excitement around the event.</p>
                <br/>
                <div className="grid">
                    <div className="c6">
                        <AwesomeSlider cssModule={SliderStyle}>
                            <div><img src={Marketing1} className="marketing-slider"/></div>
                            <div><img src={Marketing2} className="marketing-slider"/></div>
                            <div><img src={Marketing3} className="marketing-slider"/></div>
                            <div><img src={Marketing4} className="marketing-slider"/></div>
                            <div><img src={Marketing6} className="marketing-slider"/></div>
                            <div><img src={Marketing7} className="marketing-slider"/></div>
                            <div><img src={Marketing9} className="marketing-slider"/></div>
                            <div><img src={Marketing10} className="marketing-slider"/></div>
                            <div><img src={Marketing5} className="marketing-slider"/></div>
                        </AwesomeSlider>
                    </div>
                </div>
                <br/>
            </div>
            <div className="section">
                <h2 className="hackathon-title">Logo Design</h2>
                <p>I hired a graphic designer to create our vision of the logo that would be used for our hackathon T-shirt.</p>
                <br/>
                <div className="grid main">
                    <div className="c6">
                        <AwesomeSlider cssModule={SliderStyle}>
                            <div><img src={Logo1} className="marketing-slider"/></div>
                            <div><img src={Logo2} className="marketing-slider"/></div>
                            <div><img src={Logo3} className="marketing-slider"/></div>
                        </AwesomeSlider>
                    </div>
                </div>
                <br/>
                <p>I used Madisson Staires' SXSW design as inspiration, drew a sketch, and iterated on it with the designer to produce this final logo that we put on the T-shirts.</p>
            </div>
            <div className="c6 divider"></div>
            <div className="section">
                <h5 className="subtitle">Outcome</h5>
                <div className="portfolio-content">
                    <div className="">
                        <img src={Hackathon6} className="img"/>
                    </div>
                    <div className="">
                        <img src={Hackathon10} className="img"/>
                    </div>
                </div>
                <ul>
                    <li>50% native Israeli, 50% non-native Israeli participants</li>
                    <li>30% female-identifying participants (% of females in tech industry in Israel is ~11% <a target="_blank" href="https://www.honeypot.io/women-in-tech-2018/" className="hackathon-link">source</a>)</li>
                    <li>Native Israelis learned about challenges facing non-native Israelis</li>
                    <li>Native & non-native Israelis shared experiences and worked together</li>
                    <li>Sponsor companies gained visibility and scouted out talent at the event</li>
                    <li>Company mentors, judges, sponsors, and individual participants had an enjoyable, fun experience</li>
                    <li>Creative projects and initiatives were produced, for example: using data science to automatically translate municipal documents, and helping connect non-native Israelis to native Israelis who will host them for a family dinner, etc.</li>
                </ul>
                <div className="portfolio-content">
                    <div className="">
                        <img src={Hackathon8} className="img"/>
                    </div>
                    <div className="">
                        <img src={Hackathon9} className="img"/>
                    </div>
                </div>
            </div>
            <div className="c6 divider"></div>
            <div className="section">
                <h5 className="subtitle">Takeaways for future</h5>
                <ul>
                    <li>Create more opportunities for inter-team mingling and socializing during the event</li>
                    <li>Consider allowing final presentations in Hebrew or other languages</li>
                    <li>Create a larger team/committee to help with preparations and execution of the event</li>
                    <li>Allow additional time for judges to give each team feedback</li>
                    <li>Invite Renny or other comedic, entertaining MC - people really enjoyed that</li>
                    <li>Gather more feedback from sponsor companies and judges in addition to participants</li>
                </ul>
                <img src={Hackathon3} className="img"/>
            </div>
        </div>
    </div>
    <div className="bottom-nav">
        <Link to="/portfolio/what-the-tech">What the Tech</Link>
        <Link to="/portfolio/design-thinking">Design Workshops & Consulting</Link>
        <Link to="/portfolio/kfitzah">Kfitzah</Link>
        <Link to="/portfolio/art">Art</Link>
        <Link to="/">Home</Link>
    </div>
  </Layout>
)
    }